/*@import url("https://fonts.googleapis.com/css?family=Open+Sans");*/
/* @import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap"); */
/* @import url("https://fonts.googleapis.com/css?family=Lato&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap"); */

body {
	margin: 0;
	padding: 0;
	/* font-family: "Open Sans", sans-serif; */
	/* font-family: "Lato", "Helvetica", sans-serif; */
	/*background-color: #e8e8e8 !important;*/
	background-color: #f0f2f5 !important;
}

body>iframe {
	display: none
}

@font-face {
	font-family: "Material Icons";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/materialicons/v40/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format("woff2");
}

.material-icons {
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: "liga";
	-webkit-font-smoothing: antialiased;
}

.ck-editor__editable {
	min-height: 300px;
}